import React from 'react';

import hotNow from '../media/hotRightNow/hot-now.png';
import hotNow2x from '../media/hotRightNow/hot-now2x.png';
import hotNow3x from '../media/hotRightNow/hot-now3x.png';

import utilStyles from '../../../styles/util.module.scss';
import styles from './HotRightNowSection.module.scss';
import { Section } from '../../../components/Page';
import Image from '../../../components/Image';
import Grid from '../../../components/layout/Grid';
import Link from '../../../components/Link';
import Badge from '../../../components/Badge';
import Box from '../../../components/layout/Box';

const renderHotNowImg = () => (
  <Image
    className={styles.hotNowImg}
    src={hotNow}
    srcSet={`${hotNow2x} 2x, ${hotNow3x} 3x`}
    width="568"
    height="392"
    alt="Hot right now submissions"
  />
);

const HotRightNowSection = () => (
  <Section className={styles.hotRightNowSection} padding="none">
    <Grid container alignItems="center" className={styles.hotRightNowContent}>
      <Grid item xs={12} md={6}>
        <Box mb={{ xs: '1.4rem', md: '2.4rem' }}>
          <Badge text="HOT RIGHT NOW" />
        </Box>
        <h2 className={utilStyles.headingMd}>Team Engagement and Record-breaking Fundraising</h2>
        <p className={styles.hotRightNowDesc}>
          Take 5 Oil Change uses Goosechase at over 600 locations nationwide to run award-winning employee engagement,
          training, and fundraising programs.
        </p>
        <Link
          href="https://blog.goosechase.com/casestudy-take5oilchange/"
          target="_blank"
          className={styles.caseStudyLink}
          variant="underline"
        >
          See case study
        </Link>
      </Grid>
      <Grid item xs={12} md={6} alignItems="flex-end">
        {renderHotNowImg()}
      </Grid>
    </Grid>
  </Section>
);

export default HotRightNowSection;
